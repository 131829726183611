










































































































































































































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import SeletorDeTag from '@/components/produto/SeletorDeTag.vue'
import SeletorDeMarca from '@/components/produto/SeletorDeMarca.vue'
import SeletorDeCategoria from '@/views/application/produtos/EdicaoDeProduto/SeletorDeCategoria.vue'
import SeletorDeEstacao from '@/components/produto/SeletorDeEstacao.vue';
import SeletorDeGenero from '@/components/produto/SeletorDeGenero.vue';
import { FormManutencaoDeTabelaDePreco, ItemDaTabelaDePreco, TabelaDePreco } from '@/models/TabelaDePrecos';
import DialogoDeItemTabelaDePreco from './DialogoDeItemTabelaDePreco.vue';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import { formatarMoeda } from '@/shareds/formatadores';
import { formatDate } from '@/shareds/date/date-utils';
import { DataOptions } from 'vuetify';
import Confirmacao from '@/components/ui/Confirmacao.vue';
import DataTableDeCrud from '@/components/ui/DataTableDeCrud.vue';
import axios, { CancelTokenSource } from 'axios';
import { FindTabelaDePrecoUseCase } from '@/usecases';
import { obterFiltroDeBuscaDeProdutosDefault } from '@/shareds/produto-shareds';
import { FiltroDeBuscaDeProdutos } from '@/models';
import { obrigatorio } from '@/shareds/regras-de-form';
import CampoDePercentual from '@/components/ui/CampoDePercentual.vue';
import DatePicker from '@/components/ui/DatePicker.vue';
import SeletorDeTabelaDePreco from '@/components/tabeladepreco/SeletorDeTabelaDePreco.vue';

@Component({
	components: {
		SeletorDeTag,
		SeletorDeMarca,
		SeletorDeCategoria,
		SeletorDeEstacao,
		SeletorDeGenero,
		DataTableDeCrud,
		CampoDePercentual,
		DatePicker,
		SeletorDeTabelaDePreco,
	},
})

export default class DialogoDeCopiaEmMassa extends Vue {
	@Ref() dialogo!: DialogoDeItemTabelaDePreco
	@Ref() confirmacao!: Confirmacao
	@Prop({ type: String }) id!: string | undefined

	findTabelaUseCase = new FindTabelaDePrecoUseCase()
	filtro = obterFiltroDeBuscaDeProdutosDefault()
	obrigatorio = obrigatorio

	mostra = false
	tabelaDePrecoBase: TabelaDePreco | any
	itensDaTabelaDePreco: ItemDaTabelaDePreco[] = []
	AlertModule = AlertModule
	headers = [
		{ text: 'Produto', value: 'produto.nome', sortable: false },
		{ text: 'Vigência', value: 'vigencia', align: 'center'},
		{ text: 'SKU', value: 'produto.sku', sortable: false },
		{ text: "EAN's", value: 'produto.eans', sortable: false },
		{ text: 'Preço', value: 'precoFinalVarejo', sortable: false },
	]
	loading = false
	paginacao: DataOptions = {
		page: 1,
		itemsPerPage: 5,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}
	totalDeRegistros = -1
	indice: number | null = null
	cancelToken: CancelTokenSource | null = null
	busca: FiltroDeBuscaDeProdutos[] = []
	valorVendaInicial = null
	valorVendaFinal = null
	modo = 'visualizar';
	parametrosDaManutencao: FormManutencaoDeTabelaDePreco = {
		percentualDeDesconto: null,
		percentualDeCashback: null,
		diasParaEfetuarCashback: null,
		diasParaExpirarCashback: null,
		inicioVigencia: null,
		fimVigencia: null,
	};
	tabelaDeManutencao: TabelaDePreco | null = null

	async mostrar(tabela: TabelaDePreco){
		this.id = tabela.id
		this.tabelaDeManutencao = tabela
		this.mostra = true
	}

	cancelar(){
		this.modo = 'visualizar'
		this.limpaForms()
		this.mostra = false
	}

	async aplicarManutencao(){
		if (this.cancelToken) this.cancelToken.cancel()
		if(!this.id) return
		if(this.itensDaTabelaDePreco.length < 1){
			this.modo = 'configurar'
			AlertModule.setError('Sem produtos filtrados para manutenção!')
			return
		}
		if(!this.tabelaDePrecoBase) return

		try {
			this.loading = true;
			this.cancelToken = axios.CancelToken.source();
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			};

			const filtro = JSON.parse(JSON.stringify(this.filtro));
			const params = {
				skus: filtro.skus,
				categorias: filtro.categorias,
				tags: filtro.tags,
				marcas: filtro.marcas,
				estacao: this.filtro.estacao === null ? null : filtro.estacao.id,
				genero: this.filtro.genero === null ? null : filtro.genero.id,
				valorVendaInicial: !this.valorVendaInicial ? null : this.valorVendaInicial,
				valorVendaFinal: !this.valorVendaFinal ? null : this.valorVendaFinal,
				percentualDeDesconto: this.parametrosDaManutencao?.percentualDeDesconto,
				percentualDeCashback: this.parametrosDaManutencao?.percentualDeCashback,
				diasParaEfetuarCashback: this.parametrosDaManutencao?.diasParaEfetuarCashback,
				diasParaExpirarCashback: this.parametrosDaManutencao?.diasParaExpirarCashback,
				inicioVigencia: this.parametrosDaManutencao?.inicioVigencia,
				fimVigencia: this.parametrosDaManutencao?.fimVigencia,
				idTabelaDePrecoBase: this.id,
			};

			
			this.findTabelaUseCase.realizarManutencaoDaTabelaDePrecos(
				this.tabelaDePrecoBase.id,
				params,
				axiosConfig,
			)

		} catch (e) {
			if (axios.isCancel(e)) return
			AlertModule.setError(e)
		} finally {
			AlertModule.setInfo('Manutenção de tabela registrado com sucesso, acompanhe o andamento pela tela de Processos.');
			this.loading = false
			this.modo = 'visualizar';
			this.itensDaTabelaDePreco = []
			this.mostra = false
			this.limpaForms()
		}
	}

	get itensTabelaFormatadas() {
		if (!this.itensDaTabelaDePreco) return []
		return this.itensDaTabelaDePreco.map(item => ({
			...item,
			precoFinalVarejo: 'R$ ' + formatarMoeda(item.precoFinalVarejo || 0),
			vigencia: `${formatDate(item.inicioVigencia)} à ${formatDate(item.fimVigencia)}`,
		}))
	}

	aplicarIndice(indice: number) {
		this.indice = indice
		this.confirmacao.mostrar()
	}

	@Watch('paginacao', { deep: true })
	onPaginacaoChanged(newPaginacao: DataOptions, oldPaginacao: DataOptions) {
		if (newPaginacao.page !== oldPaginacao.page || newPaginacao.itemsPerPage !== oldPaginacao.itemsPerPage) {
			this.buscarItensDaTabelaDePreco();
		}
	}

	async buscarItensDaTabelaDePreco() {
		if (this.cancelToken) this.cancelToken.cancel()
		if(!this.id) return
		if(this.modo !== 'visualizar') this.modo = 'visualizar'
		if(!this.tabelaDePrecoBase){
			this.tabelaDePrecoBase = this.tabelaDeManutencao
		}

		try {
			this.loading = true
			this.cancelToken = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			const filtro = JSON.parse(JSON.stringify(this.filtro));
			const params = {
				page: (this.busca && this.busca.length > 0) ? 0 : this.paginacao.page - 1,
				size: this.paginacao.itemsPerPage,
				skus: filtro.skus,
				categorias: filtro.categorias,
				tags: filtro.tags,
				marcas: filtro.marcas,
				estacao: this.filtro.estacao === null ? null : filtro.estacao.id,
				genero: this.filtro.genero === null ? null : filtro.genero.id,
				valorVendaInicial: !this.valorVendaInicial ? null : this.valorVendaInicial,
				valorVendaFinal: !this.valorVendaFinal ? null : this.valorVendaFinal,
				tabela: this.tabelaDePrecoBase,
			};

			const pageItens = await this.findTabelaUseCase.buscaItensParaManutencaoDaTabelaDePreco(
				this.tabelaDePrecoBase.id,
				params,
				axiosConfig,
			);

			this.totalDeRegistros = pageItens.totalElements
			this.itensDaTabelaDePreco = pageItens.content
		} catch (e) {
			if (axios.isCancel(e)) return
			AlertModule.setError(e)
		} finally {
			this.loading = false
		}
	}

	limpaForms(){
		this.filtro.skus = [],
		this.filtro.categorias = [],
		this.filtro.tags = [],
		this.filtro.marcas = [],
		this.filtro.estacao = [],
		this.filtro.genero = [],
		this.valorVendaInicial = null,
		this.valorVendaFinal = null,
		this.parametrosDaManutencao.percentualDeDesconto = null,
		this.parametrosDaManutencao.percentualDeCashback = null,
		this.parametrosDaManutencao.diasParaEfetuarCashback = null,
		this.parametrosDaManutencao.diasParaExpirarCashback = null,
		this.parametrosDaManutencao.inicioVigencia = null,
		this.parametrosDaManutencao.fimVigencia = null,
		this.tabelaDePrecoBase = null
	}
}
