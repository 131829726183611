





































































































































































































































































import {
	FiltroDeItemDaTabelaDePreco,
	ItemDaTabelaDePreco,
	LojaDoPdv,
	LojaTabelaPrecoListagem,
	TabelaDePreco,
} from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import {
	DeleteItemUseCase,
	FindLojaUseCase,
	FindProdutoUseCase,
	FindTabelaDePrecoUseCase,
} from '@/usecases'
import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'
import { DataOptions } from 'vuetify'
import axios, { CancelTokenSource } from 'axios'
import DataTableDeCrud from '@/components/ui/DataTableDeCrud.vue'
import { formatarMoeda } from '@/shareds/formatadores'
import copy from 'copy-to-clipboard'
import { formatDate } from '@/shareds/date/date-utils'
import DialogoCapaDaTabelaDePreco from './DialogoCapaDaTabelaDePreco.vue'
import DialogoDeItemTabelaDePreco from './DialogoDeItemTabelaDePreco.vue'
import RangeDatePicker from '@/components/ui/RangeDatePicker.vue'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import DialogoDeCsvTabelaDePrecos from './DialogoDeCsvTabelaDePrecos.vue'
import DialogoVincularLojaComTabelaDePreco from './DialogoVincularLojaComTabelaDePreco.vue'
import moment from 'moment'
import DialogoDeCopiaEmMassa from './DialogoDeCopiaEmMassa.vue'

const FILTRO_DE_VIGENCIA = 'FILTRO_DE_VIGENCIA'

@Component({
	components: {
		DataTableDeCrud,
		DialogoCapaDaTabelaDePreco,
		DialogoDeItemTabelaDePreco,
		Confirmacao,
		DialogoDeCsvTabelaDePrecos,
		DialogoVincularLojaComTabelaDePreco,
		RangeDatePicker,
		DialogoDeCopiaEmMassa,
	},
})

export default class EdicaoDeTabelaDePrecos extends Vue {
	@Prop({ type: String }) id!: string | undefined
	@Ref() dialogoEdicaoCapa!: DialogoCapaDaTabelaDePreco
	@Ref() dialogo!: DialogoDeItemTabelaDePreco
	@Ref() dialogoDeVinculo!: DialogoVincularLojaComTabelaDePreco
	@Ref() confirmacao!: Confirmacao
	@Ref() confirmacaoTabelaDeLojas!: Confirmacao
	@Ref() dialogoCsv!: DialogoDeCsvTabelaDePrecos
	@Ref() dialogoDeCopiaEmMassa!: DialogoDeCopiaEmMassa
	tabela: TabelaDePreco | null = null
	lojasDaTabela: LojaDoPdv[] = []
	findTabelaUseCase = new FindTabelaDePrecoUseCase()
	findLojaUseCase = new FindLojaUseCase()
	findProdutoUseCase = new FindProdutoUseCase()
	deleteItemTabelaUseCase = new DeleteItemUseCase()
	AlertModule = AlertModule
	itensDaTabelaDePreco: ItemDaTabelaDePreco[] = []
	cancelToken: CancelTokenSource | null = null
	lojaTabelaPreco: LojaTabelaPrecoListagem[] = []
	paginacao: DataOptions = {
		page: 1,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}
	loading = false
	busca = ''
	totalDeRegistros = -1
	headers = [
		{ text: 'Produto', value: 'produto.nome' },
		{ text: 'SKU Pai', value: 'skuProdutoPai', sortable: false},
		{ text: 'Vigência', value: 'vigencia', align: 'center'},
		{ text: 'SKU', value: 'produto.sku' },
		{ text: "EAN's", value: 'produto.eans', sortable: false },
		{ text: 'Preço', value: 'precoFinalVarejo' },
	]
	copyToClipboard = copy
	itemDaTabelaDePreco: ItemDaTabelaDePreco[] = []
	indice: number | null = null
	filtro: FiltroDeItemDaTabelaDePreco = localStorage[FILTRO_DE_VIGENCIA]
		? JSON.parse(localStorage[FILTRO_DE_VIGENCIA])
		: {
			datas: [],
		}

	async created() {
		if (!this.id) return
		this.loading = true

		try {
			
			this.tabela = await this.findTabelaUseCase.get(this.id)
			this.buscarItensDaTabelaDePreco()
			this.buscarLojaDaTabela()
		} catch (error: any) {
			AlertModule.setError('Não foi possível carregar')
		}finally{
			this.loading = false
		}
	}

	get lojasFormatadas() {
		return this.lojasDaTabela
	}

	async buscarLojaDaTabela() {
		if (!this.id) return
		try {
			const lojasVinculadas = await this.findLojaUseCase.findLojaDaTabela(
				this.id,
			)
			this.lojasDaTabela = lojasVinculadas.content
		} catch (e) {
			AlertModule.setError(e)
		}
	}

	async buscarItensDaTabelaDePreco() {
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			if (!this.id) return
			this.loading = true
			this.cancelToken = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			const params = {
				busca: this.busca || undefined,
				page: this.busca ? -1 : this.paginacao.page - 1,
				size: this.paginacao.itemsPerPage,
				dataInicio:
					this.filtro.datas[0]
						? moment(this.filtro.datas[0]).format('YYYY-MM-DD')
						: undefined,
				dataFim:
					this.filtro.datas[1]
						? moment(this.filtro.datas[1]).format('YYYY-MM-DD')
						: undefined,
				sort: 'inicio_vigencia,desc',
			}
			
			const pageItens = await this.findTabelaUseCase.buscaItensPorTabelaDePreco(
				this.id,
				params,
				axiosConfig,
			)
			this.totalDeRegistros = pageItens.totalElements
			this.itensDaTabelaDePreco = pageItens.content
		} catch (e) {
			if (axios.isCancel(e)) return
			AlertModule.setError(e)
		} finally {
			this.loading = false
		}
	}

	get itensTabelaFormatadas() {
		if (!this.itensDaTabelaDePreco) return []
		return this.itensDaTabelaDePreco.map(item => ({
			...item,
			precoFinalVarejo: 'R$ ' + formatarMoeda(item.precoFinalVarejo || 0),
			vigencia: `${formatDate(item.inicioVigencia)} à ${formatDate(item.fimVigencia)}`,
		}))
	}

	get vigencia() {
		if (!this.tabela) return ''
		const inicioVigencia = formatDate(this.tabela.inicioVigencia)
		const fimVigencia = formatDate(this.tabela.fimVigencia)
		return `${inicioVigencia} a ${fimVigencia}`
	}
	@Watch('paginacao', { deep: true })
	onChangePaginacao = this.buscarItensDaTabelaDePreco

	editarCapa() {
		if (!this.tabela) return
		this.dialogoEdicaoCapa.mostrar(this.tabela)
	}
	salvarCapa(tabela: TabelaDePreco) {
		this.tabela = { ...tabela }
	}
	async abrirDialogo(indice: number) {
		if (!this.tabela) return
		this.dialogo.mostrar(this.tabela.id, this.tabela.inicioVigencia, this.tabela.fimVigencia, this.itensDaTabelaDePreco[indice])
	}
	async abrirDialogoDeVincualarLoja() {
		if (!this.tabela) return
		this.dialogoDeVinculo.mostrar(this.tabela, this.lojasDaTabela)
	}
	async salvarItemDaTabelaDePrecos(item: ItemDaTabelaDePreco) {
		const indice = this.itensDaTabelaDePreco.findIndex(
			({ id }) => id === item.id,
		)
		indice !== -1
			? this.itensDaTabelaDePreco.splice(indice, 1, item)
			: await this.buscarItensDaTabelaDePreco()
		this.dialogo.esconder()
	}
	async removerItem() {
		if (this.indice == null) return
		if (!this.tabela || this.indice <= -1) return
		try {
			const item = this.itensTabelaFormatadas[this.indice]
			await this.deleteItemTabelaUseCase.execute(this.tabela.id, item.id)
			this.buscarItensDaTabelaDePreco()
			AlertModule.setSuccess('Item excluído da tabela de preço')
		} catch (e) {
			AlertModule.setError(e)
		}
	}

	async removerLojaVinculada() {
		if (this.indice == null) return
		if (!this.tabela || this.indice <= -1) return

		try {
			const loja = this.lojasFormatadas[this.indice]
			await this.deleteItemTabelaUseCase.desvincularLojaDaTabela(this.tabela.id, loja.id)
			this.buscarLojaDaTabela()
			AlertModule.setSuccess('Loja desvinculada com sucesso')
		} catch (e) {
			AlertModule.setError(e)
		}
	}

	aplicarIndice(indice: number) {
		this.indice = indice
		this.confirmacao.mostrar()
	}

	aplicarIndiceLojaVinculada(indice: number) {
		this.indice = indice
		this.confirmacaoTabelaDeLojas.mostrar()
	}
}

export interface DisplayLojaTabelaDePreco {
	nomeDaLoja: string
	tipoCliente: string[]
}
